<template>
  <div id="MyAuth" class="wrap">
    <div class="formWrap">
      <h1 class="title">マイページ - 認証情報</h1>
      <div class="inputBox">
        <div class="mailAddress">
          <b-container fluid>
            <b-row class="nowMail">
              <b-col sm="6" class="labelWrap">
                <label>現在のメールアドレス</label>
              </b-col>
              <b-col sm="5" class="pl-30">
                <span class="dataText">
                  {{ mailAddress }}
                </span>
              </b-col>
            </b-row>
          </b-container>
          <v-form-checkbox
            label=""
            itemStyle="line"
            :options="[{ text: 'メールアドレスを変更する', value:true }]"
            labelSm="4"
            v-model="mailAddressChangeFlg"
          ></v-form-checkbox>
          <div class="message">
            <span :class="mailAddressChangeFlg?'':'gray'">
              メールアドレスを入力して「送信」をクリックし、確認コードを受け取ってください。
            </span>
          </div>
          <v-form-text
            label="新しいメールアドレス"
            itemStyle="line"
            :errMessage="errors.newMailAddress || []"
            v-model="newMailAddress"
            :disabled="!mailAddressChangeFlg"
            :hasBtn="true"
            :inputClass="'inlineBlock'"
            btnText="送信"
            maxLength="256"
            :btnDisabled="!newMailAddress"
            :isRequired="true"
            @click="sendMail"
          ></v-form-text>
          <div class="message">
            <span :class="isSend?'':'gray'">
              新しいメールアドレスに届いた確認コードを入力し「保存」をクリックしてください。
            </span>
          </div>
          <v-form-text
            label="確認コード"
            itemStyle="line"
            :errMessage="errors.verifyCode || []"
            v-model="verifyCode"
            maxLength="255"
            :isRequired="true"
            :disabled="!isSend"
          ></v-form-text>
        </div>
        <div class="passBox">
          <v-form-checkbox
            label=""
            itemStyle="line"
            :options="[{ text: 'パスワードを変更する', value:true }]"
            labelSm="4"
            v-model="passwordChangeFlg"
          ></v-form-checkbox>
          <div class="message">
            <span :class="passwordChangeFlg?'guide':'gray guide'">
              英大文字・英小文字・数字・記号をそれぞれ1つ以上利用し8文字以上入力してください。
            </span>
          </div>
          <v-form-text
            label="新しいパスワード"
            type="password"
            itemStyle="line"
            :errMessage="errors.newPassword || []"
            @check = 'passCheck'
            v-model="newPassword"
            maxLength="255"
            :isRequired="true"
            :disabled="!passwordChangeFlg"
          ></v-form-text>
          <v-form-text
            label="新しいパスワードの確認"
            type="password"
            itemStyle="line"
            :errMessage="errors.newPasswordConf || []"
            @check = 'passCheck'
            v-model="newPasswordConf"
            maxLength="255"
            :isRequired="true"
            :disabled="!passwordChangeFlg"
          ></v-form-text>
        </div>
      </div>
    </div>
    <div class="formWrap">
      <div class="">
        <div class="inputWrap">

        </div>
      </div>
      <div class="btnWrap">
        <b-button
          class="btn back bold"
          @click="back"
        >
          トップに戻る
        </b-button>
        <b-button
          class="btn save bold"
          @click="save"
          :disabled="
            (mailAddressChangeFlg&&!verifyCode)
            ||(passwordChangeFlg&&isDisabled)
            ||(!mailAddressChangeFlg&&!passwordChangeFlg)"
        >
          保存
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api';

export default {
  name: 'MyAuth',
  data() {
    return {
      isDisabled: true,
      isSend: false,
    };
  },
  async created() {
    this.$store.dispatch('page/onLoading');
    // 保持しているデータ情報を初期化
    this.$store.commit('authInfo/resetInputData');
    this.$store.commit('authInfo/resetErrorMsg');
    // 認証情報取得
    const res = await api.send('/api/user/myAuth')
      .catch((error) => {
        console.log(error);
        return false;
      });
    if (res !== false) {
      // 取得した認証情報を保存
      this.$store.commit('authInfo/setDbData', res.data.authInfo);
    }
    this.$store.dispatch('page/offLoading');
  },
  methods: {
    async back() {
      // 変更にチェックがあれば確認アラート表示
      const isInput = this.mailAddressChangeFlg || this.passwordChangeFlg;
      if (isInput && !await this.confirm('入力内容は破棄されます。このページから移動してもよろしいですか？')) {
        return;
      }
      this.$router.push({ name: 'gasTop' });
    },
    // 確認コード送信
    async sendMail() {
      this.$store.dispatch('page/onWaiting');
      this.$store.commit('authInfo/resetErrorMsg');
      this.verifyCode = null;
      const params = { newMailAddress: this.newMailAddress };
      const res = await api.send('/api/user/myAuth/verifyCode', params)
        .catch(async (error) => {
          this.$store.commit('authInfo/setErrorMsg', error.response.data.message);
          if (error.response.data.isAlert) {
            this.$store.dispatch('page/offWaiting');
            await this.alert(this.errors.errorMessage);
          }
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (res !== false) {
        this.isSend = true;
        await this.alert('入力いただいたメールアドレスに確認コードを送信しました。\n30分以上経過してもメールが届かない場合は、お手数ですが再度お試しください。');
      }
    },
    // 変更内容を保存
    async save() {
      if (!await this.confirm('現在の内容で保存してよろしいですか？')) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      this.$store.commit('authInfo/resetErrorMsg');
      const params = this.$store.state.authInfo.inputData;
      const res = await api.send('/api/user/myAuth/save', params)
        .catch(async (error) => {
          this.$store.commit('authInfo/setErrorMsg', error.response.data.message);
          if (error.response.data.isAlert) {
            this.$store.dispatch('page/offWaiting');
            await this.alert(this.errors.errorMessage);
          }
          console.log(error);
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (res !== false) {
        if (this.mailAddressChangeFlg) {
          this.$store.commit('authInfo/setDbData', { login_id: this.newMailAddress });
          this.$store.dispatch('auth/authChange', this.newMailAddress);
          this.isSend = false;
        }
        this.$store.commit('authInfo/resetInputData');
        this.isDisabled = true;
        await this.alert('保存が完了しました。');
      }
    },
    // 確認で入力したパスワードと同値かチェック
    passCheck() {
      this.$store.commit('authInfo/deleteErrMsg', 'newPasswordConf');
      this.isDisabled = true;
      if (!this.newPassword || !this.newPasswordConf) {
        return true;
      }
      if (this.newPassword !== this.newPasswordConf) {
        this.$store.commit(
          'authInfo/addErrorMsg',
          {
            key: 'newPasswordConf',
            msg: ['パスワードの確認とパスワードが一致するよう入力してください。'],
          },
        );
        return false;
      }
      this.isDisabled = false;
      return true;
    },
  },
  watch: {
    mailAddressChangeFlg(flg) {
      if (!flg) {
        this.newMailAddress = null;
        this.verifyCode = null;
        this.isSend = false;
        this.$store.commit('authInfo/deleteErrMsg', 'newMailAddress');
        this.$store.commit('authInfo/deleteErrMsg', 'verifyCode');
      }
    },
    passwordChangeFlg(flg) {
      if (!flg) {
        this.newPassword = null;
        this.newPasswordConf = null;
        this.isDisabled = true;
        this.$store.commit('authInfo/deleteErrMsg', 'newPasswordConf');
        this.$store.commit('authInfo/deleteErrMsg', 'newPassword');
      }
    },
  },
  computed: {
    passwordChangeFlg: {
      get() {
        return this.$store.state.authInfo.inputData.passwordChangeFlg;
      },
      set(value) {
        const SET_VALUE = value[1] || false;
        this.$store.commit('authInfo/updatePasswordChangeFlg', SET_VALUE);
      },
    },
    newPassword: {
      get() {
        return this.$store.state.authInfo.inputData.newPassword;
      },
      set(value) {
        this.$store.commit('authInfo/updateNewPassword', value);
      },
    },
    newPasswordConf: {
      get() {
        return this.$store.state.authInfo.inputData.newPasswordConf;
      },
      set(value) {
        this.$store.commit('authInfo/updateNewPasswordConf', value);
      },
    },
    mailAddressChangeFlg: {
      get() {
        return this.$store.state.authInfo.inputData.mailAddressChangeFlg;
      },
      set(value) {
        const SET_VALUE = value[1] || false;
        this.$store.commit('authInfo/updateMailAddressChangeFlg', SET_VALUE);
      },
    },
    mailAddress: {
      get() {
        return this.$store.state.authInfo.mailAddress;
      },
    },
    newMailAddress: {
      get() {
        return this.$store.state.authInfo.inputData.newMailAddress;
      },
      set(value) {
        this.$store.commit('authInfo/updateNewMamilAddress', value);
      },
    },
    verifyCode: {
      get() {
        return this.$store.state.authInfo.inputData.verifyCode;
      },
      set(value) {
        this.$store.commit('authInfo/updateVerifyCode', value);
      },
    },
    errors() {
      return this.$store.state.authInfo.errors;
    },
  },
};
</script>
<style scoped>
.title{
  color: #333333;
  font-weight: 300;
  font-size: 24px;
  margin: 0 100px;
  margin-top: 52px;
  border-bottom: solid 2px #A0A9D0;
  padding-left: 10px;
  padding-bottom: 7px;
}
.dataText{
  word-wrap: break-word;
}
.inputBox{
  /* text-align: center; */
  margin-top: 53px;
}
.passBox,
.mailAddress{
  width: 1000px;
  margin:auto;
  border-bottom: solid 1px #CCCCCC;
}
.nowMail .labelWrap label {
  width: 180px;
}
.nowMail .labelWrap {
  max-width: 300px;
  font-weight: bold;
}
.nowMail{
  margin:15px auto;
  margin-top: 30px;
}
.nowMail{
  text-align: left;
}
.nowMail .labelWrap{
  margin-right: 0;
  padding-right: 0;
  text-align: left;
}
.pl-30{
  padding-left: 30px;
}
.message{
  font-size: 13px;
  text-align: left;
  padding-left: 33.5%;
  margin-bottom: -7px;
}
.message .gray,
.nowMail .gray{
  color: #BBBBBB;
}
.nowMail input[type="text"]{
  width: 400px;
  height: 50px;
}
input[disabled]{
  border:solid 1px #F3F3F3 !important;
  background-color: #F3F3F3;
}
input[readonly]:not([disabled]){
  background-color: #FFFFFF;
}
input[readonly]:focus{
  outline: none;
  box-shadow:none;
}
button.btn{
  font-size: 18px;
  font-weight: 600;
  margin: 15px;
}
button.save{
  background-color: #122889!important;
  color: #FFFFFF!important;
}
.btnWrap{
  text-align: center;
  margin-top: 45px;
}
</style>
